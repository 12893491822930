export const msalConfig = {
  auth: {
    clientId: "fd94f8c6-501b-41eb-8bb1-5bd28eaf569e",
    authority:
      "https://login.microsoftonline.com/dab55b2c-6f19-420e-8110-8456e0b60166", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://manage-dev.xto.lu/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["api://fd94f8c6-501b-41eb-8bb1-5bd28eaf569e/reader"],
};

export const graphLoginRequest = {
  scopes: ["https://graph.microsoft.com/User.Read"], // For Microsoft Graph
};

export const api = {
  baseUrl: "https://dev.xto.lu",
};
